<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 17:07:05
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-31 09:38:55
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\culture\video\index.vue
 * @Description: 人艺视界
 *
-->
<template>
  <div class="fixed-page-mobile culture-video-index-mobile">
    <div class="container">
      <SearchInput v-model="form.title" @search="handleSearch" />
      <div class="bottom-bar">
        <div class="filter-com">
          <div class="btn-list">
            <button
              :class="{ active: form.tag === 'all' }"
              @click="handleChange('all')"
            >
              推荐
            </button>
            <button
              v-for="option in videoType"
              :key="option.id"
              :class="{ active: form.tag === option.value }"
              @click="handleChange(option.value)"
            >
              {{ option.value }}
            </button>
          </div>
        </div>
        <div class="video-list">
          <div
            v-for="item in list"
            :key="item.id"
            class="video-item"
            @click="handleDetail(item.id)"
          >
            <c-image :src="item.image" />
            <div class="info">
              <p class="line-name">
                {{ item.title }}
              </p>
              <p class="line-tag">
                <span style="margin-right: 20px">{{
                  dayjs(item.publishDate || item.updateTime).format(
                    "YYYY-MM-DD"
                  )
                }}</span>
                <span v-for="tag in item.tag" :key="tag" class="tag">{{
                  tag
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div v-if="hasNextPage" class="more-btn-box">
          <button class="more-btn" @click="handleMore">
            <i v-if="loading" class="el-icon-loading" />
            查看更多
          </button>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getArticlePage, getVideoPage } from "@/service/culture";
import SearchInput from "@/components/pc/SearchInput.vue";
export default {
  components: { SearchInput },
  data() {
    return {
      dayjs,
      form: {
        title: "",
        tag: "all",
        status: 1,
        level2: "VIDEO",
        orderSign: 2,
        orderDirection: 1,
        isShow: 1,
      },
      pager: {
        pageNo: 1,
        pageSize: 8,
      },
      total: 0,
      list: [],
      hasNextPage: true,
      loading: false,
      leftItem: {},
      leftList: [],
      rightList: [],
    };
  },
  created() {
    this.$store.dispatch("getDict", ["video-type"]);
    // this.handleSearch();
    this.getLeft();
  },
  computed: {
    videoType() {
      return this.$store.state.dictMap["video-type"] || [];
    },
  },
  methods: {
    async getLeft() {
      const res = await getVideoPage();
      this.leftList = res.data?.videoLeft || [];
      this.rightList = res.data?.videoRight || [];
      if (this.leftList.length) {
        this.leftItem = this.leftList[0];
      }
      this.list = [...this.leftList, ...this.rightList].map((item) => {
        return { ...item, title: item.name, id: item.targetId };
      });
      this.hasNextPage = false;
    },
    async getData() {
      try {
        this.loading = true;
        const params = {
          ...this.form,
        };
        if (params.tag === "all") {
          delete params.tag;
        }
        const res = await getArticlePage({
          ...this.pager,
          condition: params,
        });
        if (res.data) {
          let data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          data = data.map((item) => ({
            ...item,
            tag: item.tag ? item.tag.split(",") : [],
          }));
          if (this.pager.pageNo === 1) {
            this.list = data;
          } else {
            this.list = this.list.concat(data);
          }
          this.total = res.data.totalCount || 0;
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      } finally {
        this.loading = false;
      }
    },
    handleChange(tag) {
      this.form.tag = tag;
      if (tag === "all") {
        this.getLeft();
      } else {
        this.handleSearch();
      }
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      if (!this.hasNextPage) return;
      this.pager.pageNo++;
      this.getData();
    },
    // 查看详情
    handleDetail(id) {
      window.open(`#/culture/video/detail/${id}`);
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.culture-video-index-mobile {
  p {
    padding: 0;
    margin: 0;
  }
  .container {
    padding: 30/75rem;
    box-sizing: border-box;
  }

  .bottom-bar {
    .filter-com {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 40/75rem 0 30/75rem;
      .input {
        width: 280/75rem;
        height: 36/75rem;
        .el-input__icon {
          color: #212122;
          font-weight: bold;
        }
      }
      .btn-list {
        display: flex;
        align-items: center;
        button {
          width: auto;
          padding: 0 20/75rem;
          height: 36/75rem;
          background: #ffffff;
          border-radius: 4/75rem;
          border: 1/75rem solid #d8dade;
          cursor: pointer;
          & + button {
            margin-left: 12/75rem;
          }
        }
        .active {
          background-color: var(--primary-color);
          color: #ffffff;
        }
      }
    }
    .video-list {
      .video-item {
        background-color: #ffffff;
        cursor: pointer;
        & + .video-item {
          margin-top: 20/75rem;
        }
        .img {
          width: 100%;
          height: 340/75rem;
        }
        .info {
          width: 100%;
          padding: 20/75rem;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .line {
            &-name {
              font-size: 20/75rem;
              font-weight: 800;
              color: #212122;
              .text-overflow(2);
            }
            &-tag {
              margin-top: 10/75rem;
              display: flex;
              flex-wrap: wrap;
              font-size: 12/75rem;
              font-weight: 400;
              color: #909399;
              .tag {
                font-size: 12/75rem;
                font-weight: 400;
                color: #212122;
                background: #f8d39f;
                border-radius: 2/75rem;
                height: 20/75rem;
                line-height: 20/75rem;
                padding: 0 8/75rem;
                margin-right: 12/75rem;
              }
            }
            &-info {
              font-size: 12/75rem;
              font-weight: 400;
              color: #909399;
            }
          }
        }
      }
    }
    .more-btn-box {
      text-align: center;
      .more-btn {
        margin: 30/75rem auto 0;
        width: 422/75rem;
        height: 42/75rem;
        background: #ffffff;
        border-radius: 4/75rem;
        border: 1/75rem solid #d8dade;
        font-size: 14/75rem;
        color: #212122;
        cursor: pointer;
      }
    }
  }
}
</style>
